// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';

// Supermove
import Styled from '../Styled/Styled';

// Assets
import Animations from './animations';

const ScreenContainer = Styled.View`
  flex: 1;
`;

const POSITIONS = {
  LEFT: 'LEFT',
  TOP: 'TOP',
  RIGHT: 'RIGHT',
  BOTTOM: 'BOTTOM',
};

const ANIMATION_TIME = 250; // In milliseconds

const POSITION_ATTRIBUTES = {
  [POSITIONS.LEFT]: {
    style: {alignItems: 'flex-start'},
    openAnimation: Animations.slideInFromLeft,
    closeAnimation: Animations.slideOutToLeft,
  },
  [POSITIONS.TOP]: {
    style: {justifyContent: 'flex-start'},
    openAnimation: Animations.slideInFromTop,
    closeAnimation: Animations.slideOutToTop,
  },
  [POSITIONS.RIGHT]: {
    style: {alignItems: 'flex-end'},
    openAnimation: Animations.slideInFromRight,
    closeAnimation: Animations.slideOutToRight,
  },
  [POSITIONS.BOTTOM]: {
    style: {justifyContent: 'flex-end'},
    openAnimation: Animations.slideInFromBottom,
    closeAnimation: Animations.slideOutToBottom,
  },
};

const getStyle = ({position}) => {
  return _.get(POSITION_ATTRIBUTES, `${position}.style`);
};

const getAnimation = ({isOpen, position}) => {
  if (isOpen) {
    return _.get(POSITION_ATTRIBUTES, `${position}.openAnimation`);
  }
  return _.get(POSITION_ATTRIBUTES, `${position}.closeAnimation`);
};

const Drawer = ({
  isOpen,
  onClose,
  className,
  children,
  position,
  shouldCloseOnClickOutside,
  contentContainerStyle,
  ...props
}) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  // Depending on the component hierarchy, clicks can easily propagate to the
  // drawer overlay and cause it to immediately close right after opening. To
  // prevent this we manually handle this flag to only allow outside clicks to
  // close the drawer after the drawer has finished opening.
  const [shouldCloseOnOverlayClick, setShouldCloseOnOverlayClick] = useState(
    isOpen && shouldCloseOnClickOutside,
  );
  useEffect(() => {
    setTimeout(() => setShouldCloseOnOverlayClick(isOpen && shouldCloseOnClickOutside), 0);
  }, [isOpen, shouldCloseOnOverlayClick, setShouldCloseOnOverlayClick, shouldCloseOnClickOutside]);

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      className={contentClassName}
      portalClassName={className}
      overlayClassName={overlayClassName}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      closeTimeoutMS={ANIMATION_TIME}
      {...props}
    >
      <ScreenContainer
        pointerEvents={'box-none'}
        style={{...getStyle({position}), ...contentContainerStyle}}
      >
        {children}
      </ScreenContainer>
    </ReactModal>
  );
};

const AnimatedDrawer = styled(Drawer).attrs({
  suppressClassNameWarning: true,
})`
  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(74, 74, 74, 0.3);
    animation: ${({isOpen}) => (isOpen ? Animations.fadeIn : Animations.fadeOut)}
      ${ANIMATION_TIME}ms;
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    pointer-events: none;
    animation: ${({isOpen, position}) => getAnimation({isOpen, position})} ${ANIMATION_TIME}ms;
  }
  &__content * {
    pointer-events: all;
  }
`;

AnimatedDrawer.POSITIONS = POSITIONS;

// --------------------------------------------------
// Props
// --------------------------------------------------
AnimatedDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  position: PropTypes.string,
  shouldCloseOnClickOutside: PropTypes.bool,
  contentContainerStyle: PropTypes.object,
};

AnimatedDrawer.defaultProps = {
  position: POSITIONS.RIGHT,
  shouldCloseOnClickOutside: true,
  contentContainerStyle: {},
};

export default AnimatedDrawer;

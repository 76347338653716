// Libraries
import {createGlobalStyle} from 'styled-components';

const PrintPageStyle = createGlobalStyle`
  /* stylelint-disable property-no-vendor-prefix */
  /* stylelint-disable unit-allowed-list */

  @media print {
    body, div, h1, h2, h3, ol, ul {
      border: 0;
      float: none !important;
      position: static !important;
      overflow: visible !important;
    }

    div, img {
      -webkit-print-color-adjust: exact !important;
      color-adjust: exact !important;
    }

    [data-no-print] {
      display: none !important;
    }

    [data-print-background] {
      background-color: white !important;
    }

    [data-print-wrapper] {
      width: 100% !important;
      padding: 0 !important;
    }

    [data-print-container] {
      width: 100% !important;
    }

    [data-print] {
      overflow: visible !important;
    }

    [data-avoid-page-break] {
      page-break-inside: avoid !important;
    }
  }
`;

export default PrintPageStyle;

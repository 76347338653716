// Libraries
import _ from 'lodash';

const Duration = {
  /**
   * Given a number of minutes, returns
   * "X hr Y min" with support for negative minutes.
   */
  toDisplayTime: (minutes) => {
    const hours = Math.floor(Math.abs(minutes) / 60);
    const remainingMinutes = Math.floor(Math.abs(minutes) % 60);

    if (hours === 0 && remainingMinutes === 0) {
      return '0 min';
    } else {
      const signString = Math.sign(minutes) < 0 ? '-' : '';
      const hoursString = hours === 0 ? '' : `${hours} hr`;
      const minutesString = remainingMinutes === 0 ? '' : `${Math.abs(remainingMinutes)} min`;
      const spaceString = hoursString && minutesString ? ' ' : '';
      return `${signString}${hoursString}${spaceString}${minutesString}`;
    }
  },

  toHours: (minutes) => {
    if (!minutes) {
      return 0;
    }
    const hours = minutes / 60;
    return _.round(hours, 2);
  },

  toMinutes: (hours) => {
    if (!hours) {
      return 0;
    }
    return hours * 60;
  },
};

export default Duration;

// Libraries
import React from 'react';

const AnalyticsContext = React.createContext({
  identify: () => console.error('Must provide a `identify` function to the Analytics.Provider.'),
  skipIdentify: () =>
    console.error('Must provide a `skipIdentify` function to the Analytics.Provider.'),
  track: () => console.error('Must provide a `track` function to the Analytics.Provider.'),
  reset: () => console.error('Must provide a `reset` function to the Analytics.Provider.'),
});

export default AnalyticsContext;

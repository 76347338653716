module.exports = [{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-react-x/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-amplitude/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"f7c8ec28fa572494708a04822d9b756c"},
    },{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/supermove/supermove/apps/estimator/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
